@use '~/src/sass/abstracts/utils' as *;
@use '~/src/sass/abstracts/variables/colors';
@use '~/src/sass/abstracts/variables/structure';

.key-figures {
	@include wrapper();
}

.key-figures__wrapper {
	display: grid;
	grid-template-columns: 1fr;
	gap: 6rem;
}

.key-figures__item {
	font-weight: 800;

	.icon {
		font-size: 2.1rem;
		color: colors.$orange;
	}

	.unit {
		font-size: 4rem;
		color: colors.$orange;
		margin: 1rem 0 0;
	}

	p:not(.unit) {
		@extend %font-sm;
		color: colors.$grey-400;
		margin-top: 0.5rem;
		max-width: 80%;
	}
}

@media screen and (min-width: structure.$tablet-portrait) {
	.key-figures__wrapper {
		padding: 3.2rem 0;
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		gap: 3rem;
	}

	.key-figures__item {
		p:not(.unit) {
			max-width: 100%;
		}
	}
}
